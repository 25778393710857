import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";

export default () => (
  <Layout>
    <h1>Napaka 404</h1>

    <p><Link to="/">Prva stran</Link></p>
  </Layout>
);
